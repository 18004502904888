/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ToggleData from "./ToggleData";
import { searchResultData, EnvelopData, NotifyData, userDetail } from "./Data";
import Search from "./Search";
import UserDropdown from "./UserDropdown";
import { getAuth } from "firebase/auth";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import { firebaseConfig } from "../../../Firebase/FirebaseInit.tsx";
import { initializeApp } from "firebase/app";
// import Auth from '../../../../config/auth';

const Header = () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const [storedData, setStoredData] = useState([]);
  const auth = getAuth();
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      onSnapshot(doc(db, "users", user.email), (doc) => {
        setStoredData(doc.data());
      });
    }
  }, []);
  return (
    <div>
      <div className="navbar-bg bg-warning" />
      <nav className="navbar navbar-expand-lg main-navbar">
        <form className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <a
                href="#"
                data-toggle="sidebar"
                className="nav-link nav-link-lg"
              >
                <i className="fas fa-bars" />
              </a>
            </li>

            <li>
              <a
                href="#"
                data-toggle="search"
                className="nav-link nav-link-lg d-sm-none"
              >
                <i className="fas fa-search" />
              </a>
            </li>
          </ul>
          <Search searchResultData={searchResultData} />
        </form>
        <ul className="navbar-nav navbar-right">
          <ToggleData data={EnvelopData} />
          <ToggleData data={NotifyData} />

          <UserDropdown userDetail={{ ...userDetail, ...storedData }} />
        </ul>
      </nav>
    </div>
  );
};

export default Header;
