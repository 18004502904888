import React from "react";

export default function BootstrapCollapse() {
  return (
    <div class="main-content">
      <section class="section">
        <div class="section-header">
          <h1>Collapse</h1>
          <div class="section-header-breadcrumb">
            <div class="breadcrumb-item active">
              <a href="#">Dashboard</a>
            </div>
            <div class="breadcrumb-item">
              <a href="#">Bootstrap Components</a>
            </div>
            <div class="breadcrumb-item">Collapse</div>
          </div>
        </div>

        <div class="section-body">
          <h2 class="section-title">Collapse</h2>
          <p class="section-lead">
            Toggle the visibility of content across your project with a few
            classes and our JavaScript plugins.
          </p>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4>Simple</h4>
                </div>
                <div class="card-body">
                  <p>
                    <a
                      class="btn btn-primary"
                      data-toggle="collapse"
                      href="#collapseExample"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      Link with href
                    </a>
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      Button with data-target
                    </button>
                  </p>
                  <div class="collapse" id="collapseExample">
                    <p>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid. Nihil anim keffiyeh
                      helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Multiple Targets</h4>
                </div>
                <div class="card-body">
                  <p class="buttons">
                    <a
                      class="btn btn-primary"
                      data-toggle="collapse"
                      href="#multiCollapseExample1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="multiCollapseExample1"
                    >
                      Toggle first element
                    </a>
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-toggle="collapse"
                      data-target="#multiCollapseExample2"
                      aria-expanded="false"
                      aria-controls="multiCollapseExample2"
                    >
                      Toggle second element
                    </button>
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-toggle="collapse"
                      data-target=".multi-collapse"
                      aria-expanded="false"
                      aria-controls="multiCollapseExample1 multiCollapseExample2"
                    >
                      Toggle both elements
                    </button>
                  </p>
                  <div class="row">
                    <div class="col">
                      <div
                        class="collapse multi-collapse"
                        id="multiCollapseExample1"
                      >
                        <p>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. Nihil anim
                          keffiyeh helvetica, craft beer labore wes anderson
                          cred nesciunt sapiente ea proident.
                        </p>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="collapse multi-collapse"
                        id="multiCollapseExample2"
                      >
                        <p>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. Nihil anim
                          keffiyeh helvetica, craft beer labore wes anderson
                          cred nesciunt sapiente ea proident.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4>Accordion</h4>
                </div>
                <div class="card-body">
                  <div id="accordion">
                    <div class="accordion">
                      <div
                        class="accordion-header"
                        role="button"
                        data-toggle="collapse"
                        data-target="#panel-body-1"
                        aria-expanded="true"
                      >
                        <h4>Panel 1</h4>
                      </div>
                      <div
                        class="accordion-body collapse show"
                        id="panel-body-1"
                        data-parent="#accordion"
                      >
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </p>
                      </div>
                    </div>
                    <div class="accordion">
                      <div
                        class="accordion-header"
                        role="button"
                        data-toggle="collapse"
                        data-target="#panel-body-2"
                      >
                        <h4>Panel 2</h4>
                      </div>
                      <div
                        class="accordion-body collapse"
                        id="panel-body-2"
                        data-parent="#accordion"
                      >
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </p>
                      </div>
                    </div>
                    <div class="accordion">
                      <div
                        class="accordion-header"
                        role="button"
                        data-toggle="collapse"
                        data-target="#panel-body-3"
                      >
                        <h4>Panel 3</h4>
                      </div>
                      <div
                        class="accordion-body collapse"
                        id="panel-body-3"
                        data-parent="#accordion"
                      >
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
