export const Data = {
  menus: [
    {
      header: true,
      name: "Global",
    },
    {
      name: "Dashboard",
      icon: "fas fa-fire",
      url: "/dashboard/general",
      roles:["admin", "member"]
    },
    {
      header: true,
      name: "Members",
    },
    {
      name: "Add/Remove Members",
      icon: "fas fa-user-edit",
      url: "/blank",
      roles:["admin"]
    },
    {
      name: "View All Members",
      icon: "fas fa-users",
      url: "/dashboard/members",
      roles:["admin", "member"]
    },
    {
      header: true,
      name: "Loans",
    },
    {
      name: "Apply For Loan",
      icon: "fas fa-landmark",
      url: "/blank",
      roles:["admin", "member"]
    },
    {
      name: "Pending Loans",
      icon: "fas fa-landmark",
      url: "/blank",
      roles:["admin", "member"]
    },
    {
      header: true,
      name: "Contributions",
    },
    {
      name: "My Contributions",
      icon: "fas fa-funnel-dollar",
      url: "/blank",
      roles:["admin", "member"]
    },
    {
      name: "View All Contributions",
      icon: "fas fa-list-ul",
      url: "/blank",
      roles:["admin", "member"]
    },
    {
      name: "Approve Contributions",
      icon: "fas fa-check-square",
      url: "/blank",
      roles:["admin", "member"]
    },
    {
      name: "Add Contributions",
      icon: "fas fa-plus-circle",
      url: "/blank",
      roles:["admin",]
    },
    {
      header: true,
      name: "Events",
    },
    {
      name: "Add Events",
      icon: "fas fa-plus-circle",
      url: "/blank",
      roles:["admin"]
    },
    {
      name: "View all Events",
      icon: "fas fa-plus-circle",
      url: "/blank",
      roles:["admin", "member"]
    },
  ],
};
