import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthStatus } from "./AuthStatus";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const { loggedIn, checkingStatus } = useAuthStatus();

  return (
    <>
      {checkingStatus ? (
        <h1>Still Loading…</h1>
      ) : loggedIn ? (
        <Route
          {...restOfProps}
          render={(props) => {
            return <Component {...props} />;
          }}
        />
      ) : (
        <Route
          {...restOfProps}
          render={(props) => (
            <Redirect to={"/?auth-required&to=" + restOfProps.path} />
          )}
        />
      )}
    </>
  );
}

export default ProtectedRoute;
