import React, { Component } from "react";
import $ from "jquery";
import Logo from "../../../assets/img/chamaLogo.png";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link } from "react-router-dom";
import { doc, getFirestore, getDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../Firebase/FirebaseInit.tsx";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      type: "",
      to: "",
    };
  }
  componentDidMount() {
    $(".needs-validation").submit(function(event) {
      let form = $(this);
      if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.addClass("was-validated");
    });
    const search = this.props.location.search;
    const authRequired = new URLSearchParams(search).get("auth-required");
    const toRequired = new URLSearchParams(search).get("auth-required");
    if (authRequired !== null) {
      this.setState({
        message: "Please log in to access this page!",
        type: "warning",
        to: toRequired,
      });
    }
  }
  onsubmit(e) {
    e.preventDefault();
    const auth = getAuth();
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    this.setState({
      message: "",
      type: "",
    });
    signInWithEmailAndPassword(
      auth,
      e.target.email.value,
      e.target.password.value
    )
      .then((userCredential) => {
        this.setState({
          message: "Success! Logging you in",
          type: "success",
        });
        const docRef = doc(db, "users", userCredential.user.email);
        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            if (docSnap.data().details.approved) {
              if (this.state.to === "") {
                this.props.history.push("/dashboard/general");
              } else {
                this.props.history.push(this.state.to);
              }
            } else {
              auth.signOut().then(() => {
                this.setState({
                  message: "Your account is not yet approved",
                  type: "danger",
                });
              });
            }
          }
        });
      })
      .catch((error) => {
        this.setState({
          message: "The Email/Password is incorrect or User does not exist!",
          type: "danger",
        });
        //setIsLoading(false);
      });
  }
  render() {
    return (
      <div id="app">
        <section className="section">
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                <div className="login-brand">
                  <img
                    src={Logo}
                    alt="logo"
                    width="100"
                    className="shadow-light rounded-circle"
                  />
                </div>
                {this.state.message ? (
                  <div className={`alert alert-${this.state.type}`}>
                    {this.state.message}
                  </div>
                ) : null}
                <div className="card card-warning">
                  <div className="card-header">
                    <h4 className="text-warning">Login</h4>
                  </div>

                  <div className="card-body">
                    <form
                      method="POST"
                      noValidate
                      className="needs-validation"
                      onSubmit={(e) => this.onsubmit(e)}
                    >
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Enter Email Address"
                          tabIndex="1"
                          required
                          autoFocus
                        />
                        <div className="invalid-feedback">
                          Please fill in your email
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="d-block">
                          <label htmlFor="password" className="control-label">
                            Password
                          </label>
                          <div className="float-right">
                            <a
                              href="auth-forgot-password.html"
                              className="text-small text-warning"
                            >
                              Forgot Password?
                            </a>
                          </div>
                        </div>
                        <input
                          id="password"
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          name="password"
                          tabIndex="2"
                          required
                        />
                        <div className="invalid-feedback">
                          please fill in your password
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-warning btn-lg btn-block"
                          tabIndex="4"
                        >
                          Login
                        </button>
                      </div>
                      Don't have an accouunt?{" "}
                      <Link to="/auth/sign-up">Create an account</Link>
                    </form>
                  </div>
                </div>
                <div className="simple-footer">
                  Copyright &copy;{" "}
                  <a href="https://cryosoft.co.ke">Cryosoft Corporation LTD</a>{" "}
                  {new Date().getFullYear()}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Login;
