import React, { Component } from "react";
import MeetingsWidget from "./MeetingsWidget.tsx";

export class GeneralDashboard extends Component {
  componentDidMount() {}
  render() {
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Dashboard</h1>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="card card-statistic-1">
                <div className="card-icon bg-primary">
                  <i className="fas fa-money-bill"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>Your Contributions</h4>
                  </div>
                  <div className="card-body">Ksh. 8,000</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="card card-statistic-1">
                <div className="card-icon bg-danger">
                  <i className="far fa-clock"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>Your Pending Contributions</h4>
                  </div>
                  <div className="card-body">Ksh. 2,000</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="card card-statistic-1">
                <div className="card-icon bg-danger">
                  <i className="fas fa-file-invoice"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>Your Unpaid Fines</h4>
                  </div>
                  <div className="card-body">Ksh. 200</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="card card-statistic-1">
                <div className="card-icon bg-success">
                  <i className="fas fa-coins"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>Your Profit & Interest</h4>
                  </div>
                  <div className="card-body">Ksh. 1,200</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="card card-statistic-2">
                <div className="card-stats">
                  <div className="card-stats-title">Chama Statistics</div>
                  <div className="card-stats-items">
                    <div className="card-stats-item">
                      <div className="card-stats-item-count">11</div>
                      <div className="card-stats-item-label">Members</div>
                    </div>
                    <div className="card-stats-item">
                      <div className="card-stats-item-count">2</div>
                      <div className="card-stats-item-label">Meetings</div>
                    </div>
                    <div className="card-stats-item">
                      <div className="card-stats-item-count">6</div>
                      <div className="card-stats-item-label">Rounds</div>
                    </div>
                  </div>
                </div>
                <div className="card-icon shadow-primary bg-primary">
                  <i className="fas fa-hand-holding-usd"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>Total Contributions</h4>
                  </div>
                  <div className="card-body">Ksh. 88,000</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="card card-statistic-2">
                <div className="card-icon shadow-primary bg-primary">
                  <i className="fas fa-dollar-sign"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>Total Profits & Interest</h4>
                  </div>
                  <div className="card-body">Ksh. 18,000</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="card card-statistic-2">
                <div className="card-icon shadow-primary bg-primary">
                  <i className="fas fa-landmark"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>Available Balance For Loans</h4>
                  </div>
                  <div className="card-body">Ksh. 40,700</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Loans</h4>
                </div>
                <div className="card-body">
                  <div className="summary">
                    <div className="summary-info">
                      <div className="text-muted">Loan You Qualify for:</div>
                      <h4>Ksh. 12,000</h4>

                      <div className="d-block mt-2">
                        <a href="#">Apply for Loan</a>
                      </div>
                    </div>
                    <div className="summary-item">
                      <h6>Loan Statuses</h6>
                      <ul className="list-unstyled list-unstyled-border">
                        <li className="media">
                          <div className="media-body">
                            <div className="media-right">Ksh. 12,000</div>
                            <div className="media-title">
                              Loan You have taken
                            </div>
                            <div className="text-muted text-small">
                              Guarantor: <a href="#">Thomas Mwendwa</a>{" "}
                              <div className="bullet" /> Sunday
                            </div>
                          </div>
                        </li>
                        <li className="media">
                          <div className="media-body">
                            <div className="media-right">Ksh. 0</div>
                            <div className="media-title">Amount Paid Back</div>
                            <div className="text-muted text-small">
                              Repayment Date: <a href="#">11/6/2022</a>
                            </div>
                          </div>
                        </li>
                        <li className="media">
                          <div className="media-body">
                            <div className="media-right">Ksh. 12,720</div>
                            <div className="media-title">
                              Amount To Pay Back
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MeetingsWidget />
          </div>
        </section>
      </div>
    );
  }
}

export default GeneralDashboard;
