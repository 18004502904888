import { getFirestore, collection, query, orderBy, getDocs, limit } from "firebase/firestore";
import { FirebaseCore } from "../../Firebase/FirebaseInit";
import React from "react";
import ICalendarLink from "react-icalendar-link";

interface MeetingsWidgetProps {

}

const MeetingsWidget = () => {
    const app = FirebaseCore();
    const db = getFirestore(app);
    const [meetingData, setMeetingData] = React.useState({})
    React.useEffect(() => {
        const q = query(collection(db, "meetings"), orderBy("meeting_date", "desc"), limit(1),);
        getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                
                if(new Date(doc.data().meeting_date.toDate()).getTime()>new Date().getTime()){
                    setMeetingData(doc.data());
                }
            });
        });

    }, [])
    return (<>
    {Object.keys(meetingData).length>0?
        <div className="col-12 col-md-6 col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="text-warning">Upcoming Event</h4>
                </div>
                <div className="card-body">
                    {/* <div className="alert alert-success alert-has-icon"> 
                    <div class="alert-icon"><i class="far fa-lightbulb"></i></div>
                    <div className="alert-body">
                    <div class="alert-title">Yeey! 🥳</div>
                    You have an upcoming event. 
                    </div>
                    </div> */}
                    <div
                        className="empty-state"
                        data-height={400}
                        style={{ height: 400 }}
                    >
                        <p className="lead">
                            <b>{meetingData?.meeting_date?.toDate().toLocaleString("en-UK").toString()}</b>
                        </p>
                        <div className="empty-state-icon bg-warning">
                            <i className="fas fa-calendar" />
                        </div>
                        <h2>{meetingData.meeting_name}</h2>
                        <p className="lead">Venue: {meetingData.meeting_venue}</p>
                        <ICalendarLink event={{
                            title: meetingData.meeting_name,
                            description: meetingData.meeting_name,
                            startTime: meetingData?.meeting_date?.toDate().toISOString(),
                            location: meetingData.meeting_venue
                        }}>
                            <button type="button" className="btn btn-warning mt-4">
                                Add to Calendar
                            </button>
                        </ICalendarLink>

                    </div>
                </div>
            </div>
        </div>:<></>}
    </>);
}

export default MeetingsWidget;