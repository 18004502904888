// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyBYfETAKr8iCgh7vnSRNE1GcsrC3tYBNaU",
    authDomain: "cryosoft-sacco1.firebaseapp.com",
    projectId: "cryosoft-sacco1",
    storageBucket: "cryosoft-sacco1.appspot.com",
    messagingSenderId: "587574407651",
    appId: "1:587574407651:web:18e4c77d0c81b2da6ef0d6",
    measurementId: "G-FPT7BZXDJC"
};

// Initialize Firebase
export const FirebaseCore = () => {
    const app = initializeApp(firebaseConfig);
    //const analytics = getAnalytics(app);
    return app;
}