import React, { Component } from "react";

import UserScroll from "../../js/UserScroll";
import ProgressHeightWidth from "../../js/ProgressHeightWidth";
import { Link } from "react-router-dom";
import { getFirestore, collection, query, getDocs, limit} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../Firebase/FirebaseInit";
import { getAuth } from "firebase/auth";

export class CompUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers:[],
      limit:25,
      user:[],
      viewAll:false,
    };
  }
  componentDidMount() {
    ProgressHeightWidth();
    UserScroll();
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, "users"), limit(this.state.limit));
    const auth = getAuth();
    getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if(doc.id===auth.currentUser.email){
            this.setState({
              ...this.state,
                  user:doc.data()
                });
          }
          this.setState({
            ...this.state,
                allUsers:[...this.state.allUsers,doc.data()]
              });
        })});
       
  }
  render() {
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Members</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item active">
                <Link to="/dashboard/general">Dashboard</Link>
              </div>
              <div className="breadcrumb-item">Members</div>
            </div>
          </div>

          <div className="section-body">
            <h2 className="section-title">Members</h2>
            <p className="section-lead">
              View All Members in the Chama
            </p>

            <div className="row">
            <div className="col-12 col-sm-12 col-lg-7">
                <div className="card">
                  <div className="card-header">
                    <h4>Member Details</h4>
                    <div className="card-header-action">
                    <a href="#" className={`btn btn-icon icon-left ${this.state.viewAll?"btn-warning":"btn-success"}`} onClick={()=>this.setState({...this.state, viewAll:!this.state.viewAll })}>
                      <i className={this.state.viewAll?"fas fa-eye-slash":"fas fa-eye"}></i> {this.state.viewAll?"Hide Unapproved":"View All"}</a>
                    </div>
                  </div>
                  <div className="card-body">
                    <ul className="list-unstyled user-details list-unstyled-border list-unstyled-noborder">
                      {this.state.allUsers?.map((data, key)=>{
                        if(data.details.approved || this.state.viewAll){
                        return(<li className="media" key={key}>
                        <img
                          alt="image"
                          className="mr-3 rounded-circle"
                          width="50"
                          src={`../assets/img/avatar/avatar-${Math.floor(Math.random() * 5) +
                            1}.png`}
                        />
                        <div className="media-body">
                          <div className="media-title">{data.details.userName}{data.details.approved?<></>:<span className="text-warning"> - Waiting Approval</span>}</div>
                          {data.roles?<></>: <div className="badge badge-light">Member</div> }
                            {data.roles?.map((role, key)=><div className="badge badge-light" key={key}>{role}</div>)}
                        </div>
                        <div className="media-items">
                          <div className="media-item">
                            <div className="media-value"><a className={'text-warning'} href={`tel:${data.details.phone_number}`}>{data.details.phone_number}</a></div>
                            <div className="media-label">Phone</div>
                          </div>
                          <div className="media-item">
                            <div className="media-value">Ksh. 0</div>
                            <div className="media-label">Loans</div>
                          </div>
                          <div className="media-item">
                            <div className="media-value">Ksh. 0</div>
                            <div className="media-label">Total</div>
                          </div>
                        </div>
                      </li>)}
                      }
                      )}
                      
                    </ul>
                  </div> 
                </div>
              </div>
              <div className="col-12 col-sm-12 col-lg-5">
                <div className="card profile-widget">
                  <div className="profile-widget-header">
                    <img
                      alt="image"
                      src={`../assets/img/avatar/avatar-${Math.floor(Math.random() * 5) +
                        1}.png`}
                      className="rounded-circle profile-widget-picture"
                    />
                    <div className="profile-widget-items">
                      <div className="profile-widget-item">
                        <div className="profile-widget-item-label">Balance</div>
                        <div className="profile-widget-item-value">Ksh. 0</div>
                      </div>
                      <div className="profile-widget-item">
                        <div className="profile-widget-item-label">Loans</div>
                        <div className="profile-widget-item-value">Ksh. 0</div>
                      </div>
                      <div className="profile-widget-item">
                        <div className="profile-widget-item-label">Interest</div>
                        <div className="profile-widget-item-value">Ksh. 0</div>
                      </div>
                    </div>
                  </div>
                  <div className="profile-widget-description pb-0">
                    <div className="profile-widget-name">
                      {this.state.user?.details?.userName}
                    </div>
                    <p>
                     The following are your Roles/Permissions:
                     </p>
                     {this.state.user?.roles?<></>:<div className="badge badge-light">Member</div> }
                     {this.state.user?.roles?.map((role, key)=><div className="badge badge-light" key={key}>{role}</div>)}
                    
                  </div>
                  <div className="card-footer text-center pt-0">
                    <div className="font-weight-bold mb-2 text-small">
                      Account Actions
                    </div>
                    <a href="#" className="btn btn-danger">Disable Account</a> &nbsp;
                    <a href="#" className="btn btn-danger">Leave Chama</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CompUser;
