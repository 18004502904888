import React, { Component } from "react";
import $ from "jquery";
import Logo from "../../../assets/img/chamaLogo.png";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../Firebase/FirebaseInit.tsx";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

export class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      type: "",
      to: "",
      agreed: false,
      isSubmitted: false,
      passwordValid: true,
    };
  }

  componentDidMount() {
    $(".needs-validation").submit(function(event) {
      let form = $(this);
      if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.addClass("was-validated");
    });
    const search = this.props.location.search;
    const authRequired = new URLSearchParams(search).get("auth-required");
    const toRequired = new URLSearchParams(search).get("auth-required");
    if (authRequired !== null) {
      this.setState({
        message: "Please log in to access this page!",
        type: "warning",
        to: toRequired,
      });
    }
  }
  onsubmit(e) {
    e.persist();
    e.preventDefault();
    const auth = getAuth();
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    this.setState({
      message: "",
      type: "",
      isSubmitted: true,
    });
    if (
      e.target.email.value === "" ||
      e.target.password.value === "" ||
      e.target.full_name.value === "" ||
      e.target.phone_number.value === "" ||
      e.target.id_number.value === "" ||
      e.target.password_confirm.value === ""
    ) {
      this.setState({
        message: "Please fill all the fields",
        type: "warning",
        isSubmitted: false,
      });
    } else if (e.target.password.value !== e.target.password_confirm.value) {
      this.setState({
        ...this.state,
        passwordValid: false,
        isSubmitted: false,
        message: "The Passwords do not match",
        type: "danger",
      });
      window.scrollTo(0, 0);
    } else {
      this.setState({
        ...this.state,
        passwordValid: true,
        isSubmitted: false,
      });
      createUserWithEmailAndPassword(
        auth,
        e.target.email.value,
        e.target.password_confirm.value
      )
        .then((userCredential) => {
          const user = userCredential.user;
          if (user?.email) {
            const userRef = doc(db, "users", user?.email);
            setDoc(
              userRef,
              {
                details: {
                  userName: e.target.full_name.value,
                  phone_number: e.target.phone_number.value,
                  id_number: e.target.id_number.value,
                  approved: false,
                  date_created: new Date(),
                },
                account: {
                  balance: 0,
                  transactions: [
                    {
                      amount: 0,
                      date_created: new Date(),
                      type: "startingBalance",
                    },
                  ],
                },
              },
              { merge: true }
            )
              .then(() => {
                this.setState({
                  message: (
                    <>
                      Success! You have been signed up successfully! Please wait
                      for an approval from the admin. Click{" "}
                      <Link to="/">here</Link> to log in.
                    </>
                  ),
                  type: "success",
                  isSubmitted: false,
                });
                auth.signOut().then(() => {
                  window.scrollTo(0, 0);
                  e.target.reset();
                  $(".needs-validation").removeClass("was-validated");
                });
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        })
        .catch((error) => {
          this.setState({
            message:
              "The email might have been registered, or the strength of the password is too weak",
            type: "danger",
            isSubmitted: false,
          });
          //setIsLoading(false);
        });
    }
  }
  render() {
    return (
      <div id="app">
        <section className="section">
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2">
                <div className="login-brand">
                  <img
                    src={Logo}
                    alt="logo"
                    width="100"
                    className="shadow-light rounded-circle"
                  />
                </div>
                {this.state.message ? (
                  <div className={`alert alert-${this.state.type}`}>
                    {this.state.message}
                  </div>
                ) : null}
                <div className="card card-warning">
                  <div className="card-header">
                    <h4 className="text-warning">Register</h4>
                  </div>

                  <div className="card-body">
                    <div className="alert alert-info">
                      After Registering, you will be vetted by an administrator
                      to help us maintain a productive and friendly eco-system.
                      Once Approved, you will now be able to join our Chama.
                    </div>
                    <form
                      method="POST"
                      noValidate
                      className="needs-validation"
                      onSubmit={(e) => this.onsubmit(e)}
                    >
                      <div className="row">
                        <div className="form-group col">
                          <label htmlFor="first_name">Full Name</label>
                          <input
                            id="full_name"
                            type="text"
                            className="form-control"
                            name="full_name"
                            placeholder="Enter Full Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Enter Email Address"
                          name="email"
                          required
                        />
                        <div className="invalid-feedback"></div>
                      </div>
                      <div className="row">
                        <div className="form-group col-6">
                          <label>Phone Number</label>
                          <input
                            id="phone"
                            type="tel"
                            required
                            className="form-control"
                            name="phone_number"
                            placeholder="Enter Phone Number"
                          />
                        </div>
                        <div className="form-group col-6">
                          <label>ID Number</label>
                          <input
                            id="id_number"
                            type="number"
                            required
                            className="form-control"
                            name="id_number"
                            placeholder="Enter Your Number"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-6">
                          <label htmlFor="password" className="d-block">
                            Password
                          </label>
                          <input
                            id="password"
                            type="password"
                            required
                            className={`form-control ${
                              this.state.passwordValid ? "" : "is-invalid"
                            }`}
                            name="password"
                            placeholder="Enter Password"
                          />
                          {this.state.passwordValid ? (
                            <></>
                          ) : (
                            <div className="invalid-feedback">
                              The passwords don't match
                            </div>
                          )}
                        </div>
                        <div className="form-group col-6 has-validation">
                          <label htmlFor="password2" className="d-block">
                            Password Confirmation
                          </label>
                          <input
                            id="password2"
                            required
                            type="password"
                            className={`form-control ${
                              this.state.passwordValid ? "" : "is-invalid"
                            }`}
                            name="password_confirm"
                            placeholder="Confirm Password"
                          />
                          {this.state.passwordValid ? (
                            <></>
                          ) : (
                            <div className="invalid-feedback">
                              The passwords don't match
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-divider">Your Details</div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            name="agree"
                            className="custom-control-input"
                            id="agree"
                            onChange={(e) =>
                              this.setState({
                                ...this.state,
                                agreed: e.target.checked,
                              })
                            }
                            required
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="agree"
                          >
                            I agree that some of my data will be made public to
                            all members in the system and some data will be used
                            for my registration into the System. I also agree
                            that the data here is accurate to the best of my
                            knowledge.
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          disabled={
                            !this.state.agreed || this.state.isSubmitted
                          }
                          className={`text-center mx-auto btn btn-warning btn-lg btn-block ${
                            this.state.agreed ? "" : "disabled"
                          }`}
                        >
                          {this.state.isSubmitted
                            ? "Submitting..."
                            : "Register"}
                        </button>
                      </div>
                      Already have an account?
                      <Link to="/"> Log in here</Link>
                    </form>
                  </div>
                </div>
                <div className="simple-footer">
                  Copyright &copy;{" "}
                  <a href="https://cryosoft.co.ke">Cryosoft Corporation LTD</a>{" "}
                  {new Date().getFullYear()}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Register;
