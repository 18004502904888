import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getAuth } from "firebase/auth";

function ProtectedAuth({ component: Component, ...restOfProps }) {
  const auth = getAuth();

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        auth.currentUser && auth.currentUser !== null ? (
          <Redirect to={restOfProps.path} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default ProtectedAuth;
